import React from 'react';

function Publics() {
  return (
    <div className="space-y-6 font-comfortaa py-4">
      <div className="md:flex md:mx-auto md:space-x-8 space-y-6 px-2 md:px-0 md:space-y-0 justify-center">
        <div className="border-2 border-amethyst rounded-xl p-4 w-full md:w-[34rem]">
          <h1 className="text-2xl">
            Je suis <span className="text-atoll">parent</span> et je
            souhaite:
          </h1>
          <ul className="py-2 list-disc px-6 text-lg">
            <li>
              aider mon enfant à développer des compétences
              argumentatives et réflexives
            </li>
            <li>accompagner mon enfant à la préparation des oraux</li>
            <li>
              permettre à mon enfant de prendre confiance en lui
            </li>
          </ul>
        </div>
        <div className="border-2 border-amethyst rounded-xl p-4 w-full md:w-[34rem]">
          <h1 className="text-2xl">
            Je suis <span className="text-atoll">enseignant(e)</span>{' '}
            et je souhaite:{' '}
          </h1>
          <ul className="py-2 list-disc px-6 text-lg">
            <li>mettre en place des débats en classe</li>
            <li>travailler l'argumentation avec mes élèves</li>
            <li>
              travailler la formulation de la pensée et la précision
              du language
            </li>
            <li>
              accompagner les élèves à développer un esprit critique
            </li>
            <li>développer la créativité des mes élèves</li>
          </ul>
        </div>
      </div>
      <div className="md:flex md:mx-auto md:space-x-8 px-2 md:px-0 space-y-6 md:space-y-0 justify-center">
        <div className="border-2 border-amethyst rounded-xl p-4 w-full md:w-[34rem]">
          <h1 className="text-2xl">
            <span className="text-atoll">Je</span> souhaite:{' '}
          </h1>
          <ul className="py-2 list-disc px-6 text-lg">
            <li>développer ma répartie</li>
            <li>
              créer des réflexes pour bien penser et bien répondre
            </li>
            <li>
              m'entraîner à prendre la parole (oraux, débats,
              conférences, entretiens, pitchs, etc.)
            </li>
            <li>
              m'entraîner à débattre, à négocier, à être diplomate
            </li>
          </ul>
        </div>
        <div className="border-2 border-amethyst rounded-xl p-4 w-full md:w-[34rem]">
          <h1 className="text-2xl">
            Je fais partie d'une{' '}
            <span className="text-atoll">
              structure socio-culturelle, socio-éducative,
              médico-sociale ou d'insertion
            </span>{' '}
            et je souhaite:
          </h1>
          <ul className="py-2 list-disc px-6 text-lg">
            <li>mettre en place des débats avec mes publics</li>
            <li>redonner confiance aux personnes accueillies</li>
            <li>
              permettre l'expression et la circulation de la parole
            </li>
            <li>développer des savoirs-être et des savoirs vivre</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Publics;
