import React from 'react';
import ReactPlayer from 'react-player/lazy';
import logo from '../assets/Images/Logo.png';
import ponctuation from '../assets/Images/ponctuations/Mener-transparent.png';

function Plateforme() {
  return (
    <div className="font-comfortaa py-8">
      <div className="flex text-center justify-center space-x-4">
        <img
          className="w-24 h-24 "
          src={ponctuation}
          alt="ponctuation conversari"
        />
        <div>
          <h1 className="text-amethyst font-bold md:text-7xl text-4xl pt-3 font-raleway uppercase">
            La plateforme
          </h1>
          <div className="border-2 border-amethyst mt-2"></div>
        </div>
      </div>
      <section className="text-gray-600 body-font py-12">
        <div className="container px-5 md:py-24 md:mx-auto flex flex-col">
          <div className="lg:w-4/6 md:mx-auto">
            <div className="rounded-lg overflow-hidden md:px-44">
              <ReactPlayer
                className="rounded-lg aspect-video w-full h-full"
                controls={true}
                light={true}
                width="100%"
                height="100%"
                url="https://youtu.be/9U8oQl8YyLM"
              />
            </div>
            <div className="flex flex-col sm:flex-row mt-10">
              <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                <div className="w-20 h-20 px-2 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
                  <img src={logo} alt="logo conversari" />
                </div>
                <div className="flex flex-col items-center text-center justify-center">
                  <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">
                    La plateforme{' '}
                  </h2>
                  <div className="w-12 h-1 bg-amethyst-500 rounded mt-2 mb-4"></div>
                  <p className="text-base">
                    <span className="font-bold text-amethyst">
                      Conversari{' '}
                    </span>
                    propose des ressources transversales, exercices
                    pratiques, outils et dispositifs pour permettre
                    l'argumentation, l'écoute et la réflexion
                    critique.
                  </p>
                </div>
              </div>
              <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                <p className="leading-relaxed text-lg mb-4">
                  Fondée sur une méthodologie interdisciplinaire et
                  progressive:
                </p>
                <div>
                  <ul className="md:list-disc px-6 py-4">
                    <li>
                      Formuler sa pensée et déconstruire ses préjugés
                    </li>
                    <li>Affiner et préciser son langage</li>
                    <li>Aiguiser son esprit</li>
                    <li>Construire un raisonnement argumenté</li>
                    <li>Soutenir son propos par la forme</li>
                  </ul>
                </div>
                <div className="text-lg py-6">
                  <p>
                    Vous souhaitez tester le prototype de la
                    plateforme et nous aider à l'améliorer ?
                  </p>
                  <p>Contactez-nous:</p>
                  <br />
                  <a
                    href="mailto:contact@conversari.fr"
                    className="text-amethyst font-bold border-2 rounded-md border-amethyst p-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    contact@conversari.fr
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Plateforme;
