// @ts-nocheck
import React from 'react';
import atelier from '../assets/Images/atelier.png';
import logo from '../assets/Images/Logo3.png';
import ponctuation from '../assets/Images/ponctuations/argumenter-transparent.png';
import ReactPlayer from 'react-player/lazy';

function Ateliers() {
  return (
    <div className="font-comfortaa py-8">
      <div className="flex text-center justify-center space-x-4">
        <img
          className="w-24 h-24"
          src={ponctuation}
          alt="ponctuation conversari"
        />
        <div>
          <h1 className="text-amethyst font-bold md:text-7xl text-4xl pt-3 font-raleway uppercase">
            Les ateliers
          </h1>
          <div className="border-2 border-amethyst mt-2"></div>
        </div>
      </div>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-col">
          <div className="lg:w-4/6 mx-auto">
            <div className="rounded-lg overflow-hidden md:px-44">
              <ReactPlayer
                className="rounded-lg aspect-video w-full h-full"
                controls={true}
                light={true}
                width="100%"
                height="100%"
                url="https://youtu.be/dA5ptzqIHgw"
              />
            </div>
            <div className="flex flex-col sm:flex-row mt-10">
              <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                <div className="w-20 h-20 px-2 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
                  <img src={logo} alt="logo conversari" />
                </div>
                <div className="flex flex-col items-center text-center justify-center">
                  <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">
                    Pratiquer avec les autres!
                  </h2>
                  <div className="w-12 h-1 bg-amethyst-500 rounded mt-2 mb-4"></div>
                  <p className="text-base">
                    Rien de mieux que de s'exercer à la prise de
                    parole, à la conversation, au dialogue et au débat
                    avec d'autres personnes pour mieux vivre avec les
                    autres!
                  </p>
                </div>
              </div>
              <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                <p className="leading-relaxed text-lg mb-4">
                  Différents formats sont disponibles en fonction des
                  situations et des contextes
                </p>
                <ul className="md:list-disc px-6 py-4">
                  <li>Aboutir à un débat</li>
                  <li>
                    Travailler l'ouverture d'esprit, l'écoute et la
                    tolérance
                  </li>
                  <li>
                    Cultiver un esprit agile, qui pense vite et
                    s'exprime bien
                  </li>
                  <li>Préparer des oraux</li>
                  <li>Pratiquer l'auto-défense intellectuelle</li>
                  <li>Développer un esprit critique (EMI)</li>
                </ul>
                <div className="text-lg py-6">
                  <p>
                    Vous souhaitez mettre en place des ateliers dans
                    votre structure?
                  </p>
                  <p>Contactez-nous:</p>
                  <br />
                  <a
                    href="mailto:contact@conversari.fr"
                    className="text-amethyst font-bold border-2 rounded-md border-amethyst p-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    contact@conversari.fr
                  </a>
                </div>
              </div>
            </div>
            <div className="rounded-lg overflow-hidden pt-10">
              <img
                alt="content"
                className="object-cover object-center h-full w-full"
                src={atelier}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Ateliers;
