import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Education from './pages/Education';

function App() {
  return (
    <div>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Education />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
