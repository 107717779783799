import React from 'react';
import favicon from '../../src/assets/Images/LogoConversari-MiniFINAL-V2.jpg';

const EducationDesc = () => {
  return (
    <div className="grid justify-center items-center md:px-96 font-comfortaa relative min-w-full max-w-full">
      <div className="md:flex bg-white w-full rounded-t-md">
        <img
          className="md:w-72 md:h-72 rounded-md"
          src={favicon}
          alt="Logo conversari"
        />
        <div className="space-y-5">
          <h1 className="md:text-5xl text-2xl text-center text-amethyst-500 pt-12 px-4">
            "Inciter à penser d'abord et à parler ensuite"
          </h1>
          <p className="md:text-1xl text-lg text-black text-center">
            <span>Arthur Schopenhauer</span>, « L'art d'avoir toujours
            raison », E.J.L, 2014.
          </p>
        </div>
      </div>
      <div className="bg-white py-6 space-y-5">
        <p className="md:px-12 md:py-24 md:text-4xl text-1xl px-3 text-center">
          En latin "experientia", en français, il s'agit de la
          connaissance acquise par une longue pratique jointe à
          l'observation; action de mettre quelque chose à l'essai.
        </p>
        <p className="md:px-24 md:text-4xl text-1xl px-3 text-center">
          Repenser le rapport à soi et le rapport aux autres, pour
          sortir des rapports de force et entrer dans des rapports de
          sens (savoir être).
        </p>
      </div>
    </div>
  );
};

export default EducationDesc;
