import React, { useState } from 'react';
import imageBg from '../assets/Images/conversari-education.jpg';
import Logo from '../assets/Images/Logo3.png';
import Ateliers from '../components/Ateliers';
import EducationDesc from '../components/EducationDesc';
import Footer from '../components/Footer';
import Plateforme from '../components/Plateforme';
import Publics from '../components/Publics';
import { ExternalLink } from 'react-external-link';

function Education() {
  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener('scroll', checkScrollTop);
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className="w-full h-full">
      <header className="relative flex md:bg-pinky-100 md:h-screen overflow-hidden">
        {/* Link to external */}
        <ExternalLink href="https://conversari.fr">
          <img
            className="absolute w-80 h-24 top-10 left-6"
            src={Logo}
            alt="Logo conversari"
          />
        </ExternalLink>

        <img className="bg-cover w-full" src={imageBg} alt="" />
      </header>
      <section className="bg-gradient-to-br from-atoll-500 to-white">
        <div className="md:-mt-40 min-w-full">
          <EducationDesc />
        </div>
      </section>
      <section className="bg-gradient-to-bl from-white to-atoll-500 md:py-36">
        <Publics />
      </section>
      <section className="bg-gradient-to-br from-atoll-500 to-white">
        <Plateforme />
      </section>
      <section className="bg-gradient-to-bl from-white to-atoll-500">
        <Ateliers />
      </section>
      <Footer />
      <div className="fixed bottom-0 right-0 p-2 mb-6 mr-6 bg-opacity-25 rounded md:right-0 bg-amethyst-400 hover:bg-amethyst-500 hover:bg-opacity-40">
        <svg
          className="w-6 h-6 cursor-pointer scrollTop"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          onClick={scrollTop}
        >
          <path
            fillRule="evenodd"
            d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
}

export default Education;
